<template>
  <div class="h-screen flex flex-col justify-center relative items-center">
    <base-text textStyle="h3" classes="text-primary-700 mb-2"> Page Not Found </base-text>
    <router-link to="/">
      <base-text textStyle="p3" classes="text-primary"> Go home </base-text>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';

export default defineComponent({
  components: {
    BaseText,
  },
  setup() {
    return {};
  },
});
</script>
